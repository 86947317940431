import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
	$('.block-content-new-and-sale__wrapper-block-sale-slider').slick({
		arrows: true,
		dots: true,
		slidesToShow: 2,
		responsive: [{
				breakpoint: 530,
				settings: {
					slidesToShow: 1,
				}
			},

		]
	});
});


$(document).ready(function() {

	$('.link-menu').each(function (i) {
		$(this).attr("href", "#tab" + i);
	});
	$('.block-content-brands-item__wrapper-download').each(function (i) {
		$(this).attr("id", "tab" + i);
	});

	$('.block-content-brands-item__wrapper-brands-img-item ').click(function(e) {
		e.preventDefault();
		$(".block-content-brands-item__wrapper-brands-img .active").removeClass("active");
		$(this).addClass("active");
		var tab = $(this).find("a").attr("href");
		$(".block-content-brands-item__wrapper-download").not(tab).css({"display":"none"});
		$(tab).fadeIn(300);
	});
});

$('.popup-open').on('click', function () {
    $('.popup').removeClass('hidden')
    $('.popup').find('.popup__wrapper-input-item').show()
    $('.popup').find('.popup__wrapper-title').html('Заказать звонок')
    $('.popup').find('.popup__wrapper-text').html('Если у вас есть вопросы, оставьте свой номер: мы вам перезвоним')
 	$('.popup').find('.popup__wrapper-button').show()
 	$('.popup').find('.popup__wrapper-button-close').hide()
 	$('.popup').find('.popup__wrapper-agreement').show()
 	$('.popup').find('.popup__wrapper-img').hide()

    $(document).click(function (e){
        var div = $('.popup')
        var divsvg = $('.popup__wrapper-close')
        var divbg = $('.popup__wrapper')
        var divbut = $('.popup__wrapper-button-close-item')
        var divtext = $('.popup__wrapper-title')
        var divtext1 = $('.popup__wrapper-text')
        var divtext2 = $('.popup__wrapper-agreement-text')
        var divinput = $('.popup__wrapper-input')
        if (divsvg.is(e.target) || divbut.is(e.target) || divbg.is(e.target) || div.is(e.target) || divtext.is(e.target)
        || divtext1.is(e.target) || divtext2.is(e.target) || divinput.is(e.target)) {
                div.addClass('hidden')
        }
    });
})

$('form').submit(function (event) {
    let error = 0
    $('.popup__wrapper-input-item ').each(function () {
        if ($(this).val().length == 0) {
            $(this).addClass('error')
            error++
        } else {
            $(this).removeClass('error')
        }
    })

    if (error == 0 ) {
        let formData = $(this).serialize()
        $('.popup__wrapper-input-item ').each(function () {
            $(this).val('')
        })
        $.ajax({
            type: 'POST',
            url: 'request.php',
            data: formData
        })
        $('.popup').find('.popup__wrapper-input-item').hide()
        $('.popup').find('.popup__wrapper-title').html('Ваша Заявка принята')
        $('.popup').find('.popup__wrapper-text').html('Наш менеджер уже готовит предложение и скоро вам перезвонит. Или любой другой текст ')
        $('.popup').find('.popup__wrapper-button').hide()
 		$('.popup').find('.popup__wrapper-button-close').show()
        $('.popup').find('.popup__wrapper-agreement').hide()
        $('.popup').find('.popup__wrapper-img').show()
    }
    event.preventDefault()
})