import $ from 'jquery'
import PDFObject from 'pdfobject'


$(document).ready(function () {
	var options = {
		height: "100%",
		width: "100%"
	};
	const docPopup = $('.popup-doc');
	$('.open-doc-popup').each(function(index, elem){
  		let documentPath = $(this).data('path');
        $(this).click(function (event) {
   			docPopup.parent().find('.popup-doc').addClass('popup-doc-open');
            $('body').addClass('active');
   			PDFObject.embed(documentPath, '.popup_document', options);
        }); 
        $('.popup-doc-close').click(function (event) {
   			$('.popup-doc').removeClass('popup-doc-open');
            $('body').removeClass('active'); 			
        });
   
    }); 
});