import $ from 'jquery'


$(document).ready (function () {
	$('.block-header-burger__burger, .block-header-burger__menu-wrapper-nav-list-link').click(function (event) {
		$('body, .logo-cube, .block-header-burger__burger, .block-header-burger__backgraund, .block-header-burger__menu').toggleClass('active')
	});

});

