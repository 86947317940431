import $ from 'jquery'
import 'jquery.cookie'

$(document).ready(function(){
	if ( $.cookie('jqcookie') == null ) {
		$('.block-cooke-open').addClass('open');
	}
	else {
		$('.block-cooke-open').removeClass('open');
	}

  $("#block-cooke-button").click(function(){
  	$('.block-cooke-open').removeClass('open');
    $.cookie('jqcookie', 'Cookie Value', { expires: 7 });
   
  }); 
  
  $(".block-cooke-open").click(function(){
    $(".cookiediv").append($.cookie('jqcookie'));
  }); 

  $('.block-cooke__wrapper-close').click(function (event) {
	$('.block-cooke-open').removeClass('open');
});  
  
});
